<template>
  <div>
    <v-data-table :headers="headers" :items="paginatedAccounts" hide-default-footer :loading="loading"
                  :loading-text="message" :items-per-page="currentRows">
      <template v-slot:item.action="{ item }">
        <v-flex style="display: flex;">
          <v-menu offset-y>
            <template class="ma-2" v-slot:activator="{ on, attrs }">
              <v-btn small outlined color="primary" dark v-bind="attrs" v-on="on">
                {{ item.action }} <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link @click='handleModal(item)'>
                <v-list-title>Edit</v-list-title>
              </v-list-item>
              <v-list-item link @click='handleRedirect(item.id)'>
                <v-list-title>Accounts</v-list-title>
              </v-list-item>
              <v-list-item link @click='handleAccounts(item.id)'>
                <v-list-title>Linked Accounts</v-list-title>
              </v-list-item>
              <v-list-item v-if="item.kyb_not_approved" link @click='handleApproveKYB(item.id)'>
                <v-list-title>Approve KYB</v-list-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
      </template>
    </v-data-table>
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
                      :total-visible="7" />
      </v-col>
    </v-row>
    <div class="sidebar">
      <h2 class="heading">Generate Reports</h2>
      <v-row justify="center">
        <v-dialog v-model="dialogForGroup" persistent max-width="650px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Update Reward Tier</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Please Enter Email to Update Rewards Tier" v-model="email" />
                    <v-select :items="rewardsTier" label="Select Reward Tier" v-model="newTier" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-title v-if="successMessage" class="success-sec-btn mb-2">{{ successMessage }}</v-card-title>
            <v-card-title v-if="errorMessage" class="error-sec-btn mb-2">{{ errorMessage }}</v-card-title>
            <v-card-title v-if="message" class="error-sec-btn mb-2">{{ message }}</v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" text @click="dialogForGroup = false">
                Close
              </v-btn>
              <v-btn color="success" @click="updatePartyGroup()">
                Update Rewards Tier
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <div class="report-links">
        <router-link :to="`/skyfi-dashboard${environment}reporting/find-account`">
          <button class="black-button">Find Account number by last four digits</button>
        </router-link>
        <router-link :to="`/skyfi-dashboard${environment}reporting/transaction-within`">
          <button class="black-button">Transactions within date range</button>
        </router-link>
        <router-link :to="`/skyfi-dashboard${environment}reporting/transactions-outside`">
          <button class="black-button">Transactions outside date range</button>
        </router-link>
        <router-link :to="`/skyfi-dashboard${environment}reporting/users-within`">
          <button class="black-button">Users within date range</button>
        </router-link>
        <router-link :to="`/skyfi-dashboard${environment}reporting/users-outside`">
          <button class="black-button">Users outside date range</button>
        </router-link>
        <button @click='handleChangePartyGroup()' class="black-button">Update Rewards Tier</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountNumber: '',
      dialogForGroup: '',
      rewardsTier: ['alpha', 'standard', 'variable'],
      successMessage: '',
      errorMessage: '',
      message: 'Loading... Please wait',
      environment: '',
      accounts: [],
      loading: true,
      currentPage: 1,
      noOfPages: 1,
      rowsPerPage: [10, 15, 30, 50],
      currentRows: 50,
      headers: [
        { text: 'Account Number', value: 'account_number' },
        { text: 'Available Balance', value: 'avail_bal' },
        { text: 'Total Balance', value: 'bal' },
      ],
    };
  },
  computed: {
    formattedAccounts() {
      return this.accounts.map(account => ({
        ...account,
        avail_bal: this.formatCurrency(account.avail_bal),
        bal: this.formatCurrency(account.bal)
      }));
    },
    paginatedAccounts() {
      const start = (this.currentPage - 1) * this.currentRows;
      const end = start + this.currentRows;
      return this.formattedAccounts.slice(start, end);
    }
  },
  created() {
    const serverType = localStorage.getItem('serverType');
    if (serverType === 'staging') {
      this.environment = '/staging/';
    } else if (serverType === 'livestaging') {
      this.environment = '/live-staging/';
    }else if (serverType === 'local') {
      this.environment = '/local/';
    } else {
      this.environment = '/';
    }
    this.fetchAllBalances();
  },
  props: {
    currentServer: Object,
  },
  methods: {
    async fetchAllBalances() {
      try {
        this.loading = true;
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        
        let page = 1;
        let allAccounts = [];
        let hasMore = true;

        while (hasMore) {
          const response = await fetch(`${baseurl}admin/reporting/get_all_balances_rt?page=${page}&per_page=1000`, {
            headers: myHeaders
          });
          const data = await response.json();
          allAccounts = allAccounts.concat(data.accounts.map(account => ({
            ...account,
            avail_bal: parseFloat(account.avail_bal) || 0,
            bal: parseFloat(account.bal) || 0
          })));
          hasMore = data.accounts.length === 1000;
          page += 1;
        }

        this.accounts = allAccounts.sort((a, b) => a.avail_bal - b.avail_bal);
        this.noOfPages = Math.ceil(this.accounts.length / this.currentRows);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error('Error fetching balances:', error);
      }
    },
    pageChange() {
      this.loading = true;
      this.fetchAllBalances();
    },
    handleChangeRows() {
      this.currentPage = 1;
      this.fetchAllBalances();
    },
    handleChangePartyGroup() {
      this.dialogForGroup = true;
    },
    async updatePartyGroup() {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.environment;
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/reporting/update_rewards_tier`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            'email': this.email,
            'new_tier': this.newTier
          }),
        });
        const data = await response.json();
        this.data = JSON.stringify(response);
        if (response.status === 200) {
          this.message = data.message;
          this.successMessage = data.success;
          setTimeout(() => {
            this.successMessage = '';
          }, 8000);
        } else {
          this.errorMessage = data.error;
          setTimeout(() => {
            this.errorMessage = '';
          }, 8000);
        }
      } catch (error) {
        this.errorMessage = 'Unauthenticated';
        setTimeout(() => {
          this.errorMessage = '';
        }, 8000);
        this.$router.push('/admin-login');
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }
  },
  mounted() {
    this.fetchAllBalances();
  },
}
</script>

<style scoped>
.sidebar {
  background-color: #f4f4f4;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading {
  font-size: 40px;
}
.black-button {
  background-color: #2979ff;
  border: 2px solid #2979ff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}
.black-button:hover {
  background: none;
  color: #2979ff;
}
.heading {
  margin-bottom: 40px;
}
.report-links button {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  width: 100%;
}
.input-field {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}
</style>
